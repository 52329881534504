/* Alert */
.alert {
    &-danger,
    &-info {
        border: none;
        padding: 0;
        background-color: transparent;
    }

    &-danger {
        color: var(--red);
    }

    &-info {
        color: var(--primary);
    }
}
