/* Overwrite Bootstrap Form Classes */
.btn-primary.disabled,
.btn-primary:disabled {
    opacity: 1;
    background-color: #c9dcf4;
    border-color: #c9dcf4;
}

.btn-primary {
    &__tradius {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

/* Form validation */
.invalid-feedback {
    display: block;
    visibility: hidden;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .row .invalid-feedback,
.was-validated :invalid ~ .row .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.is-invalid ~ .row .invalid-feedback,
.is-invalid ~ .row .invalid-tooltip {
    visibility: visible;
}

/* Textual form controls */
input[type="checkbox"],
.form-check__checkbox input[type="checkbox"],
.form-check__checkbox input[type="radio"] {
    appearance: none;
    width: 16px;
    height: 16px;
    border: none;
    margin-top: 0.2rem;
    border-radius: 1px;
    border: solid 1px var(--indigo);
    background-color: var(--white);

    &:checked:after {
        content: "x";
        color: var(--gray-900);
        font-size: 14px;
        line-height: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 0;
        width: auto;
        height: auto;
        background-color: transparent;
    }

    &:disabled {
        background-color: var(--gray-400);
    }
}

input[type="radio"],
.form-check__radio input[type="radio"],
.form-check__radio input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: none;
    margin-top: 0.2rem;
    border-radius: 50%;
    border: solid 1px var(--indigo);
    background-color: var(--white);

    &:checked:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 68.6%;
        height: 68.6%;
        background-color: var(--indigo);
    }
}

.form-check {
    padding-left: 1.8rem;

    &-input {
        margin-left: -1.8rem;
    }
}

.form-check__big {
    padding-left: 0 !important;

    .form-check-input {
        margin-top: 0.2rem !important;
        margin-left: 0 !important;
        width: 26px !important;
        height: 26px !important;

        &:checked:after {
            font-size: $font-size-lg !important;
        }
    }

    .form-check-text.h4 {
        margin-top: 0.2rem;
    }

    .form-check-text.h5 {
        margin-top: 0.25rem;
    }

    .form-check-text.h6 {
        margin-top: 0.5rem;
    }

    .form-check-text {
        margin-left: 2.8rem !important;
    }
}

select.form-control {
    &[disabled] {
        appearance: none;
    }

    &:not([disabled]) {
        padding-right: 1.2rem;
        background: url('../../img/icn/icn_arrow_down.svg') no-repeat center right 5px;
        appearance: none;
    }
}

.form-control:not([readonly]):not([disabled]):not([type="date"]):not([type="checkbox"]):not([type="radio"]) {
    background: url('../../img/icn/icn_edit_field.svg') no-repeat center right;
    padding-right: 1.5rem;
}

.form-control[readonly]:not(button),
.form-control[disabled]:not(button) {
    background: transparent url('../../img/icn/icn_lock_field.svg') no-repeat center right;
    color: #33333380;
    padding-right: 1.5rem;
}

/* Custom Form Classes */
.form-label {
    color: $indigo;
    font-size: $font-size-sm;
    font-weight: 400;
    /* &:first-child {
        text-transform: uppercase;
    } */
}

.form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $indigo;
}

/* Checkboxes and radios */
.custom-control {
    margin-right: 1.5rem;
}

.custom-radio {
    &.cursor-pointer {
        .custom-control-label {
            cursor: pointer !important;
        }
    }

    &__big {
        margin-right: 0;
        padding-left: 0;

        &.custom-control {
            .custom-control-input {
                width: 25px;
                height: 25px;
            }

            .custom-control-label {
                font-size: var(--font-size-base);
                font-weight: bold;
                text-transform: uppercase;
                margin-left: 1.5rem;
                padding-left: 1.3rem;

                &:before,
                &:after {
                    width: 25px;
                    height: 25px;
                    background-size: contain;
                }
            }
        }
    }

    .custom-control {
        &-label {
            color: var(--indigo);
            font-family: $font-family-sans-serif;
            font-size: $font-size-sm;
            font-weight: 400;
            padding-left: 0.25rem;

            &::before,
            &::after {
                top: 0;
                left: -24px;
                width: 21px;
                height: 21px;
            }

            &::after {
                background: url('../../img/icn/icn_radio.svg') no-repeat center;
            }
        }

        &-input {
            width: 21px;
            height: 21px;

            &:checked {
                & ~ .custom-control-label {
                    &::before {
                        color: unset;
                        border-color: unset;
                        background-color: unset;
                    }

                    &::after {
                        background-image: url('../../img/icn/icn_radio_checked.svg');
                    }
                }
            }
        }
    }
}

.tooltip {
    &.show {
        opacity: 1;
    }

    &.bs-tooltip-right,
    &.bs-tooltip-auto[x-placement^="right"] {
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 1px;
            left: 0.4rem;
            margin-left: 1px;
            width: 15px;
            height: calc(100% - 2px);
            background-color: var(--white);
        }

        > .arrow {
            &::before {
                right: -5px !important;
            }
        }
    }

    &.bs-tooltip-left,
    &.bs-tooltip-auto[x-placement^="left"] {
        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 1px;
            right: 0.4rem;
            margin-right: 1px;
            width: 15px;
            height: calc(100% - 2px);
            background-color: var(--white);
        }

        > .arrow {
            &::before {
                left: -5px !important;
            }
        }
    }

    > .arrow {
        &::before {
            border: 1px solid var(--indigo) !important;
            width: 8px;
            height: 8px;
            background-color: white !important;
            transform: rotate(45deg);
        }
    }

    > .tooltip-inner {
        color: var(--indigo);
        text-align: left;
        position: relative;
        z-index: 0;
        border: 1px solid var(--indigo);
        border-radius: 0;
        padding: 10px 15px;
        max-width: 300px;
        background-color: var(--white);

        *:last-child {
            margin-bottom: 0;
        }
    }
}

/* Custom Navigation Form */
.nav-form {
    &.nav-tabs {
        counter-reset: nav-counter;
        border-bottom: none;
        margin-bottom: 40px;

        @media (max-width: 767px) {
            display: flex;
            position: relative;
            margin-left: -15px;
            margin-bottom: 70px;

            .nav-item {
                flex-basis: 0;
                flex-grow: 1;
                min-width: 0;
                max-width: 100%;
                width: 100%;
                padding-left: 15px;

                .txt {
                    position: absolute;
                    bottom: -40px;
                    left: 0;
                    margin-left: 15px;
                }
            }
        }

        .nav-item {
            position: static;
            margin-bottom: 0;
        }
    }

    .nav-item {
        &:not(:first-child) {
            @media (min-width: 480px) {
                margin-left: 20px;
            }
        }

        &:before {
            content: counter(nav-counter);
            counter-increment: nav-counter;
            color: var(--gray-500);
            font-size: var(--h3-font-size);
            font-weight: 300;
            line-height: 1.2;
            text-align: center;
            vertical-align: middle;
            display: inline-block;
            border: 1px solid var(--gray-500);
            border-radius: 50%;
            padding: 5px;
            width: 46px;
            //height: 45px;
            background-color: var(--white);

            @media (min-width: 480px) {
                padding: 10px;
                width: 56px;
                //height: 55px;
            }
        }

        &[aria-current] ~ .nav-item:before {
            color: var(--cyan);
            border-color: var(--cyan);
        }

        &[aria-current]:before {
            color: var(--white);
            border: none;
            background-color: var(--cyan);
        }

        &:not([aria-current]) .txt {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
        }

        .txt {
            color: var(--indigo);
            text-transform: uppercase;
            vertical-align: middle;
            display: inline-block;
            margin-left: 10px;
        }
    }
}

.underline {
    text-decoration: underline;
}

.radioIsclient label.custom-control-label{
    font-size: $h6-font-size;
}

.FeedBackTextAreaQuiz{
    resize: none;
    background: none !important;
    border: 1px solid $indigo;
    border-radius: 0.25rem;
}
.checkLabelPad-0 .custom-control-label{
   padding-left: 0; 

   &::after{
    top:2px;
    left:-23px;
   }
}

.checkLabelPad-0 .custom-control-input:not(:disabled):active ~ .custom-control-label::before{
    margin-top: 0.1rem;
    margin-left: 0.1rem;
}

.columngap-2{
    column-gap: 2rem;
}
.columngap-1_5{
    column-gap: 1.5rem;
}


.options-multiple {
    position: absolute;
    display: none;
    background-color: white;
    width: 100%;
    z-index: 2;
    box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
    border: 1px solid black;
    margin-top: 2px;

    
    & .options {
        padding-left: 15px;
        user-select: none;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;

        & input{
            margin-left: 0px;
        }

        & p {
            margin-left: 20px;
            margin-bottom: 0px;
        }


        &.selected {
            background-color: $indigo;
            color: white;
        }
        &:hover {
            background-color: #767676;
            color: white;
        }
    }


    &.open {
        display: block
    }
}

.selectedOptions{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    z-index:2;
    width:100%;
    user-select: none
}



