.modal-pdf {
    .modal-content {
        height: 725px;
        border: 0;
        border-radius: .5rem;
        overflow: inherit !important;

        .modal-header {
            border-bottom: 0;

            .close {
                position: absolute;
                top: -40px;
                right: 0;
                font-size: 1.7rem;
                font-weight: 300;
                line-height: 1.5;
                text-shadow: none;
                color: #fff;
                opacity: 1;
            }
        }

        .modal-body {
            padding: 0 1rem;

            &.modal-scrollbar {
                /*  firefox+64 
                    scrollbar color and width */
                scrollbar-color: #3D99CD #C9DCF4;
                scrollbar-width: thin;
                /* width */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: var(--gray-400);
                    box-shadow: 0;
                    border-radius: 4px;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: var(--cyan);
                    border-radius: 4px;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: var(--cyan);
                }
            }

            .react-pdf__Page__canvas {
                width: 100% !important;
                height: auto !important;
                margin: 0 auto;
            }

            .react-pdf__Page__textContent {
                //width: 100% !important;
                //height: auto !important;
            }

            .form-check-text {
                &.text-indigo {
                    color: var(--indigo);
                }
            }
        }

        .modal-footer {
            border-top: 0;
        }
    }
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}
