/* Custom Variables */

// Color system
$white:    #fff;
$gray-100: #f1f6fc;
$gray-200: #e1e8dd;
$gray-300: #e5eff4;
$gray-400: #c9dcf4;
$gray-500: #b3b3b3;
$gray-600: #666666;
$gray-900: #333333;

$blue:    #03679f;
$indigo:  #00689f;
$red:     #fc4850;
$cyan:    #3d99cd;
$teal:    #476f6e;


// Links
//
// Style anchor elements.
$link-decoration:                         underline;
$link-hover-decoration:                   none;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base:              1rem; // 16px = bootstrap
$font-size-lg:                $font-size-base * 1.25; // 16px = bootstrap
$font-size-sm:                $font-size-base * .875; // 16px = bootstrap

$font-weight-bolder:          bold;

$h1-font-size:                $font-size-base * 2.25; // 36px
$h2-font-size:                $font-size-base * 2.125; // 34px
$h3-font-size:                $font-size-base * 1.875; // 30px = bootstrap
$h4-font-size:                $font-size-base * 1.5; // 24px = bootstrap
$h5-font-size:                $font-size-base * 1.25; // 20px = bootstrap
$h6-font-size:                $font-size-base; // 16px = bootstrap

$lead-font-size:              $font-size-base; // 16px
$lead-font-weight:            normal;

$small-font-size:             87.5%;

$headings-font-weight:        300;
$headings-color:              $indigo;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

//$btn-disabled-opacity:        .65 !default;


// Forms
$input-bg:                              transparent;

$input-color:                           $gray-900;
$input-border-color:                    $indigo;
$input-border-width:                    0;

$input-border-radius:                   0;

$input-focus-box-shadow:                none;

//$form-group-margin-bottom:              2.5rem;


// Do not forget to update getting-started/theming.md!
:root {
    --gray-100: #{inspect($gray-100)};
    --gray-200: #{inspect($gray-200)};
    --gray-300: #{inspect($gray-300)};
    --gray-400: #{inspect($gray-400)};
    --gray-500: #{inspect($gray-500)};
    --gray-600: #{inspect($gray-600)};
    --gray-900: #{inspect($gray-900)};

    --font-size-base: #{inspect($font-size-base)};
    --font-size-lg: #{inspect($font-size-lg)};
    --font-size-sm: #{inspect($font-size-sm)};
    --h1-font-size: #{inspect($h1-font-size)};
    --h2-font-size: #{inspect($h2-font-size)};
    --h3-font-size: #{inspect($h3-font-size)};
    --h4-font-size: #{inspect($h4-font-size)};
    --h5-font-size: #{inspect($h5-font-size)};
    --h6-font-size: #{inspect($h6-font-size)};
}
