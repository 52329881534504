.authentication {
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width: 992px) {
        min-height: 584px;
    }

    /*.lead {
        color: var(--indigo);
    }*/

    .form-check-img {
        padding-left: 0;

        &__ico {
            align-self: center;
            flex: 0 0 55px;
            max-width: 55px;
        }

        &__label {
            align-self: center;
            flex-basis: 0;
            flex-grow: 1;
            padding-left: 15px;
            min-width: 0;
            max-width: 100%;
        }

        &:not(:only-of-type) {
            .form-check-input:active + .form-check-label,
            .form-check-input:checked + .form-check-label {
                border: 1px solid var(--indigo);
                background: var(--gray-400);

                small {
                    color: var(--indigo);
                }
            }
        }

        strong,
        small {
            display: block;
        }

        small {
            color: #666666;
            line-height: 1.35714;
        }
    }

    .form-check-label {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 1rem;
        border: 1px dashed var(--cyan);
        padding: 10px;
        min-height: 85px;
        background-color: var(--white);
    }

    .form-check-input {
        opacity: 0;

        &:focus {
            width: 100%;
            height: 100%;
            border-radius: 0;
            margin-left: 0;
            margin-top: 0;
        }

        &[disabled] ~ .form-check-label,
        &:disabled ~ .form-check-label {
            cursor: default;
            opacity: 0.65;
        }
    }
}
