.faqs {
    &__title {
        height: 58px;
        background: url(../../img/title_faqs.png) no-repeat center bottom;
        background-size: contain;

        @media (min-width: 992px) {
            height: 97px;
        }
    }

    &__sub-title {
        position: relative;
        margin-bottom: 0;
        margin-right: 40px;
    }

    .anchor-position:target {
        margin-top: -100px;
        padding-top: 100px;
    }

    .collapse .text,
    .collapsing .text {
        padding: 0 20px 20px;
    }

    .collapse.show,
    .collapsing {
        border-bottom: 1px solid var(--indigo);
        background-color: #ffffff;
    }
}
