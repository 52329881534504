.main-menu {
    .modal-dialog {
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        max-width: none;
        width: 100%;
        height: 100%;
    }

    .modal-content {
        border: none;
        height: 100%;
        background-color: transparent;
    }

    .modal-body {
        text-align: center;
        padding: 0;
    }

    .modal-header {
        border-bottom: none;
    }

    .navbar {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar.navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' fill='%23fff'%3E%3Cpath d='M.908 32.728L32.728.908l6.364 6.364-31.82 31.82-6.364-6.364z'/%3E%3Cpath d='M6.864.5l31.82 31.82-6.364 6.364L.5 6.864 6.864.5z'/%3E%3C/svg%3E");
    }

    .modal-backdrop.show {
        opacity: 1;
    }

    @media (min-width: 992px) {
        body[style*="padding-right"] & .modal {
            padding-right: 17px;
        }
    }
}

.main-nav {
    &.navbar {
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar-toggler,
.navbar-brand {
    position: relative;
    z-index: 2;
    border-radius: 0!important;
}

.navbar.navbar-dark .navbar-toggler {
    border: none;
    padding: 0;
}

.navbar.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='45' height='45'%3E%3Cdefs%3E%3Cpath id='A' d='M0 0h45v45H0z'/%3E%3CclipPath id='B'%3E%3Cuse xlink:href='%23A' clip-rule='evenodd'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23B)' fill='%23fff'%3E%3Cpath d='M0 0h45v9H0V0zm0 18h45v9H0v-9zm0 18h45v9H0v-9z'/%3E%3C/g%3E%3C/svg%3E");
}

.navbar-nav {
    .nav-link {
        font-size: var(--h1-font-size);
        text-transform: uppercase;
        display: inline-block;
    }
}
