/* Icons */
.icn {
    &-tooltip {
        color: var(--white);
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        text-align: center;
        vertical-align: top;
        /* align-items: center;
        justify-content: center;
        display: flex; */
        display: inline-block;
        position: relative;
        margin-left: 5px;
        padding: 2px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: var(--indigo);

        &__invert {
            color: var(--indigo);
            background-color: var(--white);
        }
    }

    &-approved {
        vertical-align: middle;
        display: inline-block;
        width: 28px;
        height: 23px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../img/icn/icn_arrow_custom.svg);
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='28' height='23'%3E%3Cpath d='M3.807 6.2S1.102 4.134.2 12.82 1.553 23.564 4.71 22.878 24.003 6.837 26.804 3.22C29.286.018 27.48-.897 25.227.932c-7.892 6.4-18.94 14.63-18.94 14.63S5.6 5.962 3.807 6.2' fill='%233d99cd'/%3E%3C/svg%3E");
    }
}

/* Button Icons */
.btn-arrow-icon {
    content: "";
    vertical-align: middle;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../../img/icn/icn_arrow_custom.svg)
}

.btn-faqs {
    text-decoration: none;
    text-transform: none;
    border-radius: 0;
    padding: 25px 20px;
    background-repeat: repeat-x;
    background-position: left bottom;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1112' height='2'%3E%3Cpath d='M1 1h1110' fill='none' stroke='%2300689f' stroke-linecap='square' stroke-dasharray='5 5'/%3E%3C/svg%3E");

    &.active {
        background-image: none;
        background-color: var(--white);
        padding: 25px 20px 0;

        .btn-arrow-icon {
            transform: rotateX(180deg);
        }
    }

    .btn-arrow-icon {
        position: absolute;
        top: 5px;
        right: -40px;
        width: 28px;
        height: 22px;
    }
}
