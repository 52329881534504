.benefits {
    &__title {
        text-align: center;

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--img {
            margin-top: 10px;
            height: 58px;
            background: url(../../img/title_benefits.png) no-repeat center bottom;
            background-size: contain;

            @media (min-width: 992px) {
                width: 471px;
                height: 88px;
            }
        }
    }

    .anchor-position:target {
        margin-top: -100px;
        padding-top: 100px;
    }

    .container {
        justify-content: center;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-bottom: 1rem;
        }

        strong {
            color: var(--indigo);
        }
    }

    .btn-arrow-icon {
        margin-top: 8px;
        width: 37px;
        height: 29px;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 86px;
        width: 86px;
        margin-bottom: -80px;
        border-radius: 50%;
        background-color: var(--white);

        @media (min-width: 992px) {
            margin-bottom: -60px;
        }
    }

    @media (min-width: 992px) {
        .img {
            margin-top: -80px;
        }
    }
}
