.create-card {
    .anchor-position:target {
        margin-top: -140px;
        padding-top: 140px;

        .page-logoutbar ~ & {
            margin-top: -192px;
            padding-top: 192px;
        }
    }
}
