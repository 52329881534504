
@mixin btnplaycolor($color) {
    
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23#{$color}' class='bi bi-play-fill' viewBox='0 0 16 16'%3E%3Cpath d='m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z'/%3E%3C/svg%3E") no-repeat center center;
    
}


.banner {
    .img {
        @media (max-width: 575px) {
            width: 300px;
        }

        @media (min-width: 576px) and (max-width: 991px) {
            width: 350px;
        }

        @media (max-width: 991px) {
            transform: rotate(9deg);
        }
    }

    .btnplay{
        width: 2rem;
        height: 100%;
        @include btnplaycolor("03679f");
        background-size: contain;
    }
    
    .btn-outline-primary:hover{
        &>.btnplay{
    
            @include btnplaycolor("fff");
            background-size: contain;
        }
    }
    

    @media (min-width: 992px) {
        display: flex;
        align-items: center;
        height: 500px;
        background: url(../../img/bg/bg_illustrations.png) no-repeat center top;
        background-size: 100% 100%;

        h1, div {
            color: var(--white); 
        }

        .btnplay{
            width: 2rem;
            height: 100%;
            @include btnplaycolor("fff");
            background-size: contain;
        }
        .btn-outline-primary:hover{
            &>.btnplay{
        
                @include btnplaycolor("4aa9a3");
                background-size: contain;
            }
        }
        .p_btnplay{
            width: 100%;
        }
        .btn-banner{
            padding-left: 8px !important;
            padding-right: 0px !important;
            border-color: var(--white);
            color: var(--white);

            &:hover, &:not(:disabled):not(.disabled):active{
                background-color: var(--white);
                border-color: var(--white);
                color: #4aa9a3;
            }
        }
        
    }
}


