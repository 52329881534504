.main-form {
	margin-bottom: 60px;
	padding-top: 40px;

	&__header {
		margin-bottom: 55px;

		@media (min-width: 768px) {
			margin-bottom: 40px;
		}

		.nav-form.nav-tabs {
			margin-bottom: 0;
		}
	}
	// &__step2 {
	// }

	&__step3 {
		.icn-position {
			position: relative;
			//margin-left: 45px;
			/*@media (min-width: 576px) {
                margin-left: 0;
            }*/
			.icn-approved {
				position: absolute;
				left: -40px;
			}
		}

		.sub-btnbox {
			background-color: var(--gray-100);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;

			&__title {
				text-decoration: underline;
			}

			&__note {
				color: var(--gray-600);
			}
		}
	}

	.description {
		color: var(--indigo);
		margin-bottom: 2rem;
	}

	.product__card-caixaiu {
		.img {
			display: none;
		}

		@media (max-width: 991px) {
			.img__mobile {
				display: inline-block;
				width: 350px;
				transform: rotate(9deg);
			}
		}

		@media (min-width: 992px) {
			.img__desktop {
				display: inline-block;
			}
		}

		ul {
			list-style-type: none;
			padding-left: 0;

			li {
				margin-bottom: 1rem;
			}

			strong {
				color: var(--indigo);
			}
		}
	}

	.product__caixadireta {
		padding-top: 45px;
		background-color: var(--gray-100);

		.img-caixadireta {
			margin-bottom: -40px;
			transform: rotate(9deg);

			@media (min-width: 992px) {
				margin-top: -30px;
				margin-bottom: -50px;
			}
		}
	}

	.product__card-caixa-isic {
		margin-bottom: 45px;

		h2,
		h3 {
			color: var(--teal);
		}

		.product__bg {
			margin-bottom: 20px;
			padding-top: 70px;
			background-color: var(--gray-200);

			@media (min-width: 992px) {
				background: url(../../img/bg/bg_card-cauxa-isic.jpg) no-repeat center top;
			}
		}

		.align-flex-end {
			align-self: flex-end;
		}
	}

	.title-info-docs {
		position: relative;
		left: 0;
		top: 0;
		margin-left: 45px;
	}

	.title-info-docs::before {
		content: "";
		position: absolute;
		left: 2px;
		bottom: 5px;
		width: 28px;
		height: 31px;
		margin-left: -45px;
		background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg width='28' height='31' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E %3Cg%3E %3Cpath id='svg_3' d='M 12.242 13.764 C 12.396 15.383 12.47 18.461 11.249 20.883 C 11.149 21.079 11.052 21.268 10.953 21.45 C 8.828 22.106 6.584 23.35 6.21 24.719 C 6.064 25.26 6.199 25.801 6.58 26.204 C 6.978 26.621 7.472 26.843 8.013 26.843 C 9.321 26.843 10.681 25.585 12.166 23.003 C 12.706 22.869 15.811 22.488 16.463 22.495 C 18.254 24.339 19.703 25.235 20.886 25.235 C 21.629 25.235 22.269 24.857 22.643 24.196 C 22.901 23.734 22.902 23.2 22.645 22.732 C 21.902 21.38 19.01 20.83 17.274 20.705 C 17.125 20.54 16.974 20.369 16.819 20.192 C 15.042 18.147 14.316 15.143 14.047 13.573 C 14.014 13.213 13.976 12.915 13.943 12.695 C 13.829 11.865 13.311 11.739 13.012 11.739 C 12.753 11.739 12.505 11.853 12.332 12.05 C 12.054 12.367 12.096 12.71 12.124 12.939 C 12.149 13.165 12.189 13.462 12.242 13.764 Z M 8.09 25.008 C 8.259 24.819 8.592 24.538 9.095 24.236 C 8.625 24.749 8.279 24.961 8.09 25.008 Z M 20.791 23.409 C 20.64 23.39 20.35 23.314 19.886 23.026 C 20.286 23.157 20.591 23.293 20.791 23.409 Z M 13.819 18.882 C 14.152 19.562 14.522 20.18 14.927 20.735 C 14.729 20.754 13.329 20.937 13.205 20.953 C 13.464 20.315 13.668 19.622 13.819 18.882 Z' style='fill: rgb(102, 164, 198);'/%3E %3Cpath id='svg_4' d='M 10.363 4.112 L 2.995 11.494 L 2.995 31.112 L 25.995 31.112 L 25.995 4.112 L 10.363 4.112 Z M 9.713 7.216 L 9.713 10.488 L 6.448 10.488 L 9.713 7.216 Z M 24.215 29.424 L 4.773 29.424 L 4.773 12.176 L 11.492 12.176 L 11.492 5.8 L 24.217 5.8 L 24.217 29.424 L 24.215 29.424 Z' style='fill: rgb(102, 164, 198);'/%3E %3C/g%3E %3Cpath style='fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);' d='M 11.706 4.669'/%3E %3C/svg%3E");
	}

	.product__card-continente {
		position: relative;
		left: 0;
		top: 0;
		padding-top: 35px;
		padding-bottom: 35px;

		&:before,
		&:after {
			content: "";
			position: absolute;
			z-index: -1;
			left: 0;
			width: 100%;
			height: 181px;
			background: url(../../img/bg/bg_shadow.png) no-repeat center top;
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
			transform: rotateX(180deg);
		}
	}

	.product__conta-poupanca {
		@media (min-width: 992px) and (max-width: 1199px) {
			/*padding-top: 40px;
        padding-bottom: 40px;*/
			// background: url(../../img/bg/bg_conta-poupanca.png) no-repeat right top;
			position: relative;
			overflow: hidden;

			.cropImage {
				position: absolute;
				content: "";
				max-width: 100%;
				top: 10px;
				right: 0;
				bottom: 0;
				left: 70px;
			}
		}
	}

	.product__card-student {
		background-repeat: repeat-x;
		background-position: left bottom;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1112' height='2'%3E%3Cpath d='M1 1h1110' fill='none' stroke='%2300689f' stroke-linecap='square' stroke-dasharray='5 5'/%3E%3C/svg%3E");

		.btn.btn-cards {
			text-transform: none;
			margin-bottom: 0;
			border-radius: 0;
			padding: 1.5rem 3rem 0 1.5rem;
			background-color: transparent;
			background-repeat: repeat-x;
			background-position: left top;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1112' height='2'%3E%3Cpath d='M1 1h1110' fill='none' stroke='%2300689f' stroke-linecap='square' stroke-dasharray='5 5'/%3E%3C/svg%3E");
		}

		.collapse .text,
		.collapsing .text {
			padding: 0 20px 20px;
		}
	}

	@media (min-width: 365px) and (max-width: 575px) {
		.icn-apps {
			.col-6 {
				max-width: 157px;
			}
		}
	}

	.native-slider {
		text-align: left;
		overflow-x: auto;
		/*scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;*/
		display: flex;
		flex-wrap: initial;
		margin-left: auto;
		margin-right: auto;

		&.app {
			width: 171px;
		}

		&.app .slide {
			text-align: center;
			vertical-align: top;
			scroll-snap-align: center;
			padding-bottom: 15px;
			min-width: auto;
			width: 100%;

			.img {
				width: 171px;
			}
		}

		@media (max-width: 767px) {
			.slide {
				text-align: center;
				vertical-align: top;
				scroll-snap-align: center;
				padding-bottom: 15px;
				min-width: 85%;
				width: 100%;
			}
		}

		.img {
			max-width: none;
		}
	}

	.carousel {
		&.caption {
			.carousel-item {
				display: block;
				float: none;
				margin-right: 0;
				transition: none;
				backface-visibility: visible;

				&:not(.active):not(.carousel-next):not(.carousel-prev) {
					color: var(--gray-500);
				}

				&.active,
				&-next,
				&-prev {
					font-weight: 700;
				}
			}
		}

		&-indicators {
			bottom: -55px;

			li {
				opacity: 1;
				position: relative;
				width: 6px;
				height: 6px;
				border-radius: 100%;
				background-color: var(--cyan);

				&.active {
					margin-left: 9px;
					margin-right: 9px;
					width: 38px;
					border-radius: 0;

					&:before,
					&:after {
						content: "";
						position: absolute;
						width: 6px;
						height: 100%;
						background-color: var(--cyan);
					}

					&:before {
						left: -5px;
						border-radius: 6px 0 0 6px;
					}

					&:after {
						right: -5px;
						border-radius: 0 6px 6px 0;
					}
				}
			}
		}

		.carousel-item img {
			cursor: pointer;
		}
	}

	.react-pdf__Wrapper {
		overflow: auto;
	}

	.react-pdf__Document {
		@media (max-width: 991px) {
			cursor: zoom-in;
			overflow: hidden;
		}

		@media (min-width: 992px) {
			min-height: 584px;
		}

		&.zoom-page {
			cursor: zoom-out;
			min-width: 992px;
		}

		.react-pdf__message--loading {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 584px;
		}
	}

	.react-pdf__Page {
		border: 1px solid var(--gray-600);

		@media (min-width: 992px) {
			min-height: 584px;
		}

		&:not(:last-child) {
			margin-bottom: 1rem;

			@media (min-width: 768px) {
				margin-bottom: 2rem;
			}

			@media (min-width: 992px) {
				margin-bottom: 3rem;
			}
		}

		&__svg {
			max-width: 100%;
			width: 100% !important;
			height: auto !important;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.suggestions {
	border: 1px solid var(--indigo);
	margin-top: 0.25rem;
	margin-bottom: 0;
	padding-left: 0rem;
	z-index: 1;
	background-color: #fff;

	li {
		list-style-type: none;
		cursor: pointer;
		padding-left: 0.8rem;
		padding-right: 0.8rem;

		&:hover {
			background-color: var(--indigo);
			color: var(--white);
		}
	}
}


.pdf-canvas{
	display: flex !important;
	justify-content: center;

	&>canvas{
		border: 1px solid black;
		margin: 10px;

		@media (max-width: 480px) {
			width: 300px;
		}
		@media (min-width: 480px) {
			width: 300px;
		}
		@media (min-width: 576px) {
			width: 400px;
		}
		@media (min-width: 728px) {
			width: 500px;
		}
		@media (min-width: 992px) {
			width: 800px;
		}
	}
}

.pg-viewer-wrapper{
	overflow-y: hidden;
}

.customUploadFile {
	width: 190px;
	height: 190px;
	border: 1px dashed var(--indigo);
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
	cursor: pointer;

	&:hover {
		svg path[fill],
		svg g[fill] {
			fill: #023654;
		}

		.btn-link {
			color: #023654;
			text-decoration: none;
		}
	}

	&__wrapper {
		height: 100%;
		width: 100%;

		.result {
			position: relative;
			height: 75%;
			width: 100%;
			display: flex;
			justify-content: center;
			flex-direction: column;
			// img {
			//     object-fit: contain;
			// }
			.uploadFileThumb {
				object-fit: contain;
				height: 100px;
			}

			.uploadFileName {
				//height: 20%;
				color: var(--indigo);
				font-size: 12px;
				font-weight: 400;
				text-align: center;
				text-transform: uppercase;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.controllers {
			height: 25%;
			width: 100%;
			display: flex;
			justify-content: center;

			.btn-link {
				text-transform: uppercase;

				&:hover {
					svg path[fill],
					svg g[fill] {
						fill: #023654;
					}
				}
			}

			.buttonDivisor {
				width: 1px;
				height: 100%;
				border: solid 1px #00689f;
				margin: auto 1rem;
			}
		}
	}
}
