.error-pages {
    padding-top: 60px;

    .bg-img {
        padding-bottom: 60px;
        min-height: 730px;
        background-image: url(../../img/bg/bg_error-page.png);
        background-repeat: no-repeat;
        background-position: center bottom;

        @media (min-width: 576px) and (max-width: 767px) {
            min-height: 630px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            min-height: 620px;
            background-position: left bottom;
        }

        @media (min-width: 992px) {
            min-height: 670px;
            background-position: left bottom;
        }
    }
}
